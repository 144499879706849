import request from '@/common/request/request'
import gSetting from '@/common/setting/index'
const apiUrl = gSetting.common.apiUrl;

function register(data) { return request({ url: '/Tenant/Register', method: 'post', data, baseURL: apiUrl }) }
function resetPassword(data) { return request({ url: '/Tenant/ResetPassword', method: 'post', data, baseURL: apiUrl }) }
function verifyCodeSend(data) { return request({ url: '/VerifyCode/Send', method: 'post', data, baseURL: apiUrl }) }
function appointmentCreate(data) { return request({ url: '/Appointment/Create', method: 'post', data, baseURL: apiUrl }) }

export default {
  register,
  resetPassword,
  verifyCodeSend,
  appointmentCreate
}